<template>
  <div>
<!--     <div class="labels">
      <CategoryLabel :category="category" class="each-label"/>
      <LevelLabel :level="level" class="each-label"/>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "Labels",
  props: ['category', 'level']
}
</script>

<style lang="scss" scoped>
  .labels {
    position: absolute;
    top: 20px;
    right: 20px;
    color: white;

    display: flex;
    align-items: center;

    z-index: 105;
  }

  .each-label {
    margin: 0 5px;
  }
</style>

