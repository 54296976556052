<template>
  <div>
    <h1 class="page-title">Contatos</h1>

    <div v-for="contact in notSeenContacts" class="contact not-seen">
      <div class="contact-upper">
        <p class="contact-info">{{contact.name}} <span class="contact-email">{{contact.email}}</span></p>
        <p class="contact-date">{{contact.created_at | moment("dddd, MMMM Do YYYY")}}</p>
      </div>
      <p class="contact-message">{{contact.message}}</p>

      <btn class="seen-btn" primary @click="markAsSeen(contact)">Marcar como lida</btn>
    </div>

    <div v-for="contact in seenContacts" class="contact seen">
      <div class="contact-upper">
        <p class="contact-info">{{contact.name}} <span class="contact-email">{{contact.email}}</span></p>
        <p class="contact-date">{{contact.created_at | moment("dddd, MMMM Do YYYY")}}</p>
      </div>
      <p class="contact-message">{{contact.message}}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "admin-contacts",
  data: function () {
    return {
      notSeenContacts: [],
      seenContacts: [],
    }
  },
  async mounted() {
    const response = await axios.get('/client/contacts')
    this.notSeenContacts = response.data.not_seen_contacts;
    this.seenContacts = response.data.seen_contacts;
  },
  methods: {
    async markAsSeen(contact) {
      const response = await axios.patch(`/client/contacts/${contact.id}`)

      this.notSeenContacts = this.notSeenContacts.filter((con) => con.id !== contact.id);
      this.seenContacts.unshift(contact);
    }
  }
}
</script>

<style lang="scss" scoped>
  .contact {
    width: 100%;
    padding: 24px 24px;
    background-color: rgba(250,250,250,1);
    box-shadow: 1px 1px rgba(225,225,225,1);
    margin-bottom: 12px;

    .contact-upper {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .contact-info {
      font-size: 18px;
      color: rgba(20,20,20,1);
      font-weight: bold;
    }

    .contact-email {
      padding-left: 12px;
      font-size: 16px;
      color: rgba(100,100,100,1);
      font-weight: normal;
    }

    .contact-message {
      font-size: 16px;
      color: rgba(50,50,50,1);
      font-family: roboto;
    }

    .contact-date {
      text-align: right;
      font-size: 16px;
      color: rgba(50,50,50,1);
    }
  }

  .not-seen {
    border: 2px solid #161616;
  }

  .seen-btn {
    margin-top: 12px;
    padding: 8px 24px;
  }
</style>

