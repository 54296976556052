<template>
  <div class="admin-padding">
    <div class="navbar-spacer" />

    <div class="tabs">
      <p v-for="tab in tabs" @click="changeTab(tab)" class="tab page-subtitle" :class="{ active: currentTab === tab.name }">{{tab.text}}</p>
    </div>

    <div class="navbar-spacer" style="height: 40px;"/>

    <admin-control-panel v-show="currentTab === 'control-panel'" @show-snackbar="showSnackbar"/>
    <admin-contacts v-show="currentTab === 'contacts'" @show-snackbar="showSnackbar"/>
    <admin-analytics v-show="currentTab === 'analytics'" @show-snackbar="showSnackbar"/>
    <admin-posts v-show="currentTab === 'posts'" @show-snackbar="showSnackbar"/>

  </div>
</template>

<script>

export default {
  name: "admin-dashboard",
  data: function () {
    return {
      requireAdmin: true,
      currentTab: "control-panel",
      tabs: [
        { name: "control-panel", text: "Painel de controle" },
        { name: "contacts", text: "Contatos" },
        { name: "analytics", text: "Analytics" },
        { name: "posts", text: "Publicações" },
      ]
    }
  },
  async mounted() {

  },
  computed: {

  },
  components: {

  },
  methods: {
    changeTab(tab) {
      this.currentTab = tab.name;
    },
    showSnackbar(css, message) {
      console.log("emitou")
      this.$emit('show-snackbar', css, message)
    },
  }
}
</script>

<style lang="scss" scoped>
  .tabs {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .tab {
    flex: 0 0 20%;
    font-size: 18px;
    padding: 12px 8px;
    color: #161616;
    text-align: center;

    &:hover {
      background-color: #646464;
      cursor: pointer;
      color: white;
      transition: 300ms;
    }
  }

  .active {
    background-color: #161616;
    color: white;
  }
</style>

