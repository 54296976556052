<template>
  <div>
    <router-link :to="'post/' + post.slug">
      <div>
        <div class="home-image-place">
          <div class="home-image bannerAnimation" :style="{ backgroundImage: `url('${post.thumbnail}')` }">
          </div>

          <p class="countAnimation post-count">{{this.romanize(this.posts.length - this.index)}}</p>
          <div class="post-details latestPostSlide" >
            <h2 class="post-title">{{post.title}}</h2>
            <p class="post-description">{{post.description}}</p>
            <p class="post-date">{{post.date}}</p>
          </div>
        </div>
      </div>
    </router-link>
  </div>

</template>

<script>
export default {
  name: "FullScreenPost",
  props: ['post', 'index', 'posts'],
  data: function () {
    return {
      hoverDetails: false,
      computedHeight: 'auto',
      showDetails: false,
    }
  },
  computed: {
    postDescriptionHeight() {
      if (this.hoverDetails) {
        return { height: `${this.computedHeight}px` }
      } else {
        return { height: 0 }
      }
    },
  },
  methods: {
    romanize: function(num) {
      const romanMap = [
        { value: 1000, numeral: 'M' },
        { value: 900, numeral: 'CM' },
        { value: 500, numeral: 'D' },
        { value: 400, numeral: 'CD' },
        { value: 100, numeral: 'C' },
        { value: 90, numeral: 'XC' },
        { value: 50, numeral: 'L' },
        { value: 40, numeral: 'XL' },
        { value: 10, numeral: 'X' },
        { value: 9, numeral: 'IX' },
        { value: 5, numeral: 'V' },
        { value: 4, numeral: 'IV' },
        { value: 1, numeral: 'I' }
      ];

      let roman = '';
      romanMap.forEach(({ value, numeral }) => {
          while (num >= value) {
              roman += numeral;
              num -= value;
          }
      });
      return roman;
    }
  },
}
</script>

<style lang="scss" scoped>
  @import "../../stylesheets/base/variables";

  a:link, a:active, a:visited, a:hover {
    text-decoration: none;
  }

  .post-count {
    position: absolute;
    top: 30px;
    right: 60px;
    text-decoration: none;
    font-size: 60px;
    font-weight: lighter;
    text-align: left;
    color: white;
    margin: 0;
    opacity: 0;

    @media screen and (max-width: 1400px) {
      font-size: 40px;
      top: 20px;
      right: 20px;
    }
  }

    @keyframes countEnter {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .countAnimation {
    animation: countEnter 4s ease-in;
    animation-delay: 0.6s;
    animation-fill-mode: forwards;
  }

  .home-image-place {
    position: relative;
    width: 100%;
    height: 100vh;
    background-color: #161616;
		overflow-x: hidden;
		overflow-y: hidden;
  }

  .home-image {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
  }

  .post-details {
    position: absolute;
    top: 54vh;
    left: 5vw;
    background-color: rgba(16, 16, 16, 0.1);
    width: 80vw;
    padding: 20px 80px;

    &.right {
      left: auto;
      right: 5vw;
      text-align: right !important;
    }

    .post-title {
      font-size: 80px;
      color: white;
      margin-bottom: 10px;

      &.right {
        text-align: right !important;
      }
    }
    .post-description {
      font-size: 24px;
      font-weight: lighter;
      color: white;
      margin: 0;
      margin-bottom: 12px;

      &.right {
        text-align: right !important;
      }
    }
    .post-date {
      font-size: 20px;
      font-weight: lighter;
      color: white;
      margin: 0;

      &.right {
        text-align: right !important;
      }
    }
  }

  @media screen and (max-width: 1400px) {
    .post-details {
      top: 60vh;
      left: 5vw;
      width: 80vw;
      padding: 20px;

      .post-title {
        font-size: 46px;
      }
      .post-description {
        font-size: 24px;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .post-details {
      top: 52vh;
      left: 0;
      bottom: 0;
      width: 100vw;
      padding: 40px;
      height: 50vh;

      .post-title {
        font-size: 30px;
      }
      .post-description {
        font-size: 14px;
      }
    }
  }

  @keyframes bannerEnter {
    0% {
      opacity: 0;
      transform: scale(1);
    }
    100% {
      opacity: 1;
      transform: scale(1.08);
    }
  }

  .bannerAnimation {
    animation: bannerEnter 8s ease-out;
    transform: scale(1.08);
  }

  // Mobile version

  .m-each-post {
    background-size: cover;
    background-position: center;
    height: 100vh;

    position: relative;
  }

  .m-post-details {
    position: absolute;

    bottom: 0;
    right: 0;
    left: 0;
    padding: 0 10px;

    background: rgba(0,0,0,0.4);
    padding: 20px 20px 0 20px;
  }

  .m-post-date {
    font-size: 14px;
    color: white;
  }

  .m-post-title {
    font-size: 16px;
    font-weight: bold;
    color: white;
  }

  .m-post-description {
    font-size: 12px;
    font-weight: normal;
    color: white;
    text-align: justify;
  }

</style>

