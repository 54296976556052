<template>
  <div>
    <p :style="{ backgroundColor: level.color }" class="post-level">
      <i class="fas fa-star" v-for="index in level.int"></i>
      {{level.name}}
    </p>
  </div>
</template>

<script>
export default {
  name: "LevelLabel",
  props: ['level']
}
</script>

<style lang="scss" scoped>
  .post-level {
    padding: 6px;
    border-radius: 2px;
    font-size: 13px;

    color: white;

    i {
      padding-right: 0px;
    }
  }
</style>

