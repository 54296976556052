<template>
  <div>
    <div>
      <div class="post" v-if="post">
        <div class="navbar-spacer" />

        <transition name="fade">
          <div
            v-show="showImage"
            class="post-image"
            v-bind:style="{ backgroundImage: 'url(' + post.thumbnail + ')' }"
          ></div>
        </transition>

        <div class="mobile-padding">
          <transition name="fade-title">
            <div v-show="showTitle" class="post-content">
              <h1 class="post-title">{{ post.title }}</h1>
            </div>
          </transition>

          <div v-if="!showContent" class="content-placeholder" />

          <transition name="slide-fade">
            <div
              v-if="post.kind === 'article' && post.article.length"
              v-show="showContent"
              v-html="post.article"
              class="post-content"
            >
              {{ post.article }}
            </div>

            <div
              v-else-if="post.kind === 'notebook' && post.image_urls.length"
              v-show="showContent"
              class="post-content"
            >
                <div
                  v-for="image_url in post.image_urls"
                  style="display: flex; justify-content: center; align-items: center;"
                  :key="image_url"
                >
                  <img class="notebook-image" :src="image_url"></img>
                </div>
            </div>

            <p v-else>{{ t("post.no_content") }}</p>
          </transition>

          <hr />

          <div class="post-finish">
            <div class="btn clap-btn full-btn" @click="clap()">
              <i ref="clapIcon" class="fas fa-sign-language"></i
              ><span>{{ claps }} {{ clapWord }}</span>
            </div>

            <div
              class="signature"
              :style="{
                backgroundImage: `url('${require('images/signature.png')}')`,
              }"
            >
              <p class="signature-date t/xt-right">
                {{ post.date | moment("DD/MM/YYYY") }}
              </p>
            </div>
          </div>

          <router-link class="return-button" :to="'/'">
            <p>{{ t("components.return") }}</p>
          </router-link>

          <router-link v-if="sessionAdmin" :to="'/text-editor/' + post.slug">
            <div class="btn btn-primary full-btn">
              <p>Editar artigo</p>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "post",
  data: function () {
    return {
      loading: true,
      post: null,
      visit: null,
      claps: 0,
      showImage: false,
      showTitle: false,
      showContent: false,
    };
  },
  created() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  },
  async mounted() {
    await this.loadPost();
    await this.startVisit();

    window.addEventListener("beforeunload", (event) => {
      this.finishVisit();
    });

    setTimeout(() => {
      this.showImage = true;
      setTimeout(() => {
        this.showTitle = true;
        setTimeout(() => (this.showContent = true), 400);
      }, 800);
    }, 800);
  },
  computed: {
    clapWord() {
      if (this.claps === 1) {
        return this.t("post.clap");
      } else {
        return this.t("post.claps");
      }
    },
    sessionAdmin() {
      return this.$store.getters.sessionAdmin;
    },
  },
  methods: {
    async loadPost() {
      const postSlug = this.$route.params.slug;
      const url = `/client/posts/${postSlug}`;

      const response = await axios.get(url);

      if (response.data.retired_at) {
        this.$router.push({ name: "home" });
        this.$emit("show-snackbar", "failure", "Esse artigo foi aposentado");
      }

      if (!response.data.published_at) {
        this.$router.push({ name: "home" });
        this.$emit("show-snackbar", "failure", "Esse artigo está em rascunho");
      }

      this.loading = false;
      this.post = response.data;
      this.claps = this.post.claps;
    },
    async startVisit() {
      if (!this.$store.getters.sessionId) await this.$sleep(800);
      if (!this.$store.getters.sessionId) await this.$sleep(800);
      if (!this.$store.getters.sessionId) await this.$sleep(800);

      const response = await axios.post(`/client/visits`, {
        session_id: this.$store.getters.sessionId,
        post_slug: this.post.slug,
      });

      this.visit = response.data;
    },
    async finishVisit() {
      await axios.delete(`/client/visits/${this.visit.id}`);
    },
    clap() {
      this.claps += 1;

      axios.patch(`/client/visits/${this.visit.id}`);

      const clapIcon = this.$refs.clapIcon;

      clapIcon.classList.remove("pop-element");
      clapIcon.classList.add("pop-element");
      setTimeout(function () {
        clapIcon.classList.remove("pop-element");
      }, 150);
    },
  },
  beforeDestroy() {
    this.finishVisit();
  },
};
</script>

<style lang="scss" scoped>
@import "../../stylesheets/base/variables";

hr {
  margin: 40px 0 20px 0;
}

.post {
  padding: 80px 350px 50px 350px;
}

@media screen and (max-width: 1400px) {
  .post {
    padding: 0px;
  }
}

.post-title {
  font-size: 50px;
  font-weight: 700;
}

@media screen and (max-width: 768px) {
  .post-title {
    font-size: 40px;
  }
}

.post-image {
  width: 100%;
  height: 50vh;
  background-size: cover;
  background-position: center;
  margin-bottom: 30px;
}

.notebook-image {
  width: 30vw;
  height: 40vw;
  margin: 0 auto;
  margin-bottom: 30px;
}

.pop-element {
  animation: pop 0.15s linear 1;
}

.content-placeholder {
  height: 200vh;
}

@keyframes pop {
  50% {
    transform: scale(1.2);
  }
}

.post-finish {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .clap-btn {
    border: 1px solid rgba(160, 160, 160, 1);
    border-radius: 10px;
    color: rgba(160, 160, 160, 1);
    background: transparent;
    flex: 0 0 65%;

    i {
      font-size: 40px;
      padding-right: 30px;
    }

    span {
      position: relative;
      bottom: 8px;
      font-size: 18px;
      font-weight: 400;
    }

    &:hover {
      background: rgba(160, 160, 160, 1);
      color: white;
      transition: 300ms ease;
    }
  }

  .signature {
    flex: 0 0 30%;
    position: relative;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    height: 175px;

    .signature-date {
      position: absolute;
      right: 12%;
      bottom: 12%;
      color: rgba(70, 70, 70, 1);
      text-align: right;
      margin: 0;
      font-size: 18px;
    }
  }
}

@media screen and (max-width: 768px) {
  .post-finish {
    display: block;

    .clap-btn {
      margin: 10px 0;
      width: 100%;

      i {
        padding-right: 20px;
      }

      span {
        font-size: 16px;
      }
    }

    .signature {
      position: relative;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      height: 140px;
      width: 100%;
      margin-bottom: 30px;

      .signature-date {
        position: absolute;
        right: 12%;
        bottom: 12%;
        color: rgba(70, 70, 70, 1);
        text-align: right;
        margin: 0;
        font-size: 18px;
      }
    }
  }

  .notebook-image {
    width: 100vw;
    height: 133vw;
  }
}

.ql-indent-1 {
  padding-left: 32px;
  margin: 0;
}
</style>
