var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{ref:"buttonElement",staticClass:"btn waves-effect centered",class:{
          'btn-primary': _vm.primary,
          'btn-secondary': _vm.secondary,
          'btn-outline': _vm.outline,
          'btn-success': _vm.success,
          'btn-danger': _vm.danger,
          'btn-flat': _vm.flat,
          'btn-raised': !_vm.flat && !_vm.outline,
        },attrs:{"type":_vm.submit ? 'submit' : 'button',"disabled":_vm.disabled},on:{"click":function($event){return _vm.$emit('click')}}},[_c('fade-transition',{attrs:{"out-in":""}},[(_vm.loading)?_c('progress-spinner',{attrs:{"small":""}}):_c('span',[_vm._t("default")],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }