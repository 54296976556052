<template>
  <div>
    <div class="mobile-padding">
      <h1 class="page-title">Painel de controle</h1>

      <h2 class="page-subtitle">Artigos em rascunho</h2>

      <div class="btn btn-secondary list-post list-post-draft" v-for="draft in drafts" @click="editPost(draft)">
        <p class="list-post-slug">{{draft.slug}}</p>
        <p class="list-post-title">{{draft.title}}</p>
        <p class="list-post-date">{{draft.date}}</p>
      </div>

      <h2 class="page-subtitle">Adicionar artigo</h2>

      <router-link :to="'/text-editor/'">
        <div class="btn btn-primary full-btn">
          <p>Criar um novo artigo</p>
        </div>
      </router-link>

      <h2 class="page-subtitle">Artigos em destaque</h2>

      <multiselect v-model="featured.first" :options="posts" placeholder="Primeiro artigo em destaque" label="title" track-by="slug" style="margin-bottom: 10px;"></multiselect>
      <multiselect v-model="featured.second" :options="posts" placeholder="Segundo artigo em destaque" label="title" track-by="slug" style="margin-bottom: 10px;"></multiselect>
      <multiselect v-model="featured.third" :options="posts" placeholder="Terceiro artigo em destaque" label="title" track-by="slug" style="margin-bottom: 10px;"></multiselect>

      <div class="btn btn-secondary full-btn" @click="updateFeatured">
        <p>Atualizar artigos em destaque</p>
      </div>

      <h2 class="page-subtitle">Artigos aposentados</h2>

      <div class="btn btn-secondary list-post list-post-retired" v-for="retired in retireds" @click="editPost(retired)">
        <p class="list-post-slug">{{retired.slug}}</p>
        <p class="list-post-title">{{retired.title}}</p>
        <p class="list-post-date">{{retired.date}}</p>
      </div>

      <h2 class="page-subtitle">Administrar categorias</h2>

      <h5 class="page-subtitle">Editar categoria</h5>
      <multiselect :options="categories" placeholder="Atuais categorias" v-model="editCategory" :custom-label="customLabelCategory" track-by="id" style="margin-bottom: 10px;" @input="loadCategory"></multiselect>
      <div>
        <div class="new-category">
          <input class="category-create-name" type="text" placeholder="Criar nova categoria" v-model="editCategory.name">
          <input class="category-create-icon" type="text" placeholder="Icone" v-model="editCategory.icon">
          <div class="btn pick-color-btn" :style=" { backgroundColor: editCategory.color }" @click="pickColorOpen = !pickColorOpen">
            <p style="color: white; margin: 0;">Color</p>
            <chrome-picker v-if="pickColorOpen" class="color-picker" :value="editCategory.color" @input="updateCategoryEditColor"></chrome-picker>
          </div>
          <div class="btn category-create-btn" :class="{ 'btn-disabled': !editCategoryEnabled, 'btn-primary': editCategoryEnabled }" @click="editCategoryRun">Editar</div>
        </div>
      </div>

      <h5 class="page-subtitle">Criar categoria</h5>
      <div>
        <div class="new-category">
          <input class="category-create-name" type="text" placeholder="Criar nova categoria" v-model="newCategory.name">
          <input class="category-create-icon" type="text" placeholder="Icone" v-model="newCategory.icon">
          <div class="btn pick-color-btn" :style=" { backgroundColor: newCategory.color }" @click="pickColorOpen = !pickColorOpen">
            <p style="color: white; margin: 0;">Color</p>
            <chrome-picker v-if="pickColorOpen" class="color-picker" :value="newCategory.color" @input="updateCategoryCreateColor"></chrome-picker>
          </div>
          <div class="btn category-create-btn" :class="{ 'btn-disabled': !addCategoryEnabled, 'btn-primary': addCategoryEnabled }" @click="createCategory">Criar</div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import VueColor from 'vue-color'

export default {
  name: "admin-control-panel",
  data: function () {
    return {
      requireAdmin: true,
      posts: [],
      drafts: [],
      retireds: [],
      categories: [],
      newCategory: {
        icon: '',
        name: '',
        color: '#194d33',
      },
      editCategory: {
        id: 0,
        icon: '',
        name: 'Selecionar categoria',
        color: '#000',
      },
      pickColorOpen: false,
      featured: {
        first: null,
        second: null,
        third: null,
      }
    }
  },
  async mounted() {
    const responsePosts = await axios.get('/client/posts')

    this.posts = responsePosts.data.posts
    this.drafts = responsePosts.data.drafts
    this.retireds = responsePosts.data.retireds
    this.featured.first = responsePosts.data.featured[0]
    this.featured.second = responsePosts.data.featured[1]
    this.featured.third = responsePosts.data.featured[2]

    const responseCategories = await axios.get('/client/categories')

    this.categories = responseCategories.data.categories
  },
  computed: {
    editCategoryEnabled() {
      return this.editCategory.id !== 0
    },
    addCategoryEnabled() {
      return this.newCategory.name.length >= 3
    }
  },
  components: {
    Multiselect,
    'chrome-picker': VueColor.Chrome,
  },
  methods: {
    async updateFeatured() {
      const url = 'client/update_featured'

      await axios.patch(url, this.featured).then(() => {
        this.$emit('show-snackbar', 'success', 'Artigos em destaque atualizados')
      }).catch(() => {
        this.$emit('show-snackbar', 'failure', 'Falha ao atualizar artigos em destaque')
      })
    },
    editPost(post) {
      this.$router.push({ name: 'text-editor-edit', params: { slug: post.slug } })
    },
    async editCategoryRun() {
      if (!this.editCategoryEnabled) {
        this.$emit('show-snackbar', 'failure', 'Selecione uma categoria para editar')
        return false
      }

      const url = `client/categories/${this.editCategory.id}`

      axios.patch(url, { category: this.editCategory }).then((response) => {
        if (response.data.category.id) {
          const updatedCategoryIndex = this.categories.findIndex((cat) => cat.id === this.editCategory.id)
          this.categories[updatedCategoryIndex] = response.data.category
          this.$emit('show-snackbar', 'success', 'Categoria editada com sucesso')
        } else {
          this.$emit('show-snackbar', 'failure', 'Erro ao editar categoria')
        }
      }).catch(() => {
        this.$emit('show-snackbar', 'failure', 'Erro ao editar categoria')
      })
    },
    async createCategory() {
      if (!this.addCategoryEnabled) {
        this.$emit('show-snackbar', 'failure', 'A categoria deve ter pelo menos 3 caracteres')
        return false
      }

      const url = 'client/categories'

      axios.post(url, { category: this.newCategory }).then((response) => {
        if (response.data.category.id) {
          this.categories.push(response.data.category)
          this.$emit('show-snackbar', 'success', 'Categoria criada com sucesso')
        } else {
          this.$emit('show-snackbar', 'failure', 'Erro ao criar categoria')
        }
      }).catch(() => {
        this.$emit('show-snackbar', 'failure', 'Erro ao criar categoria')
      })
    },
    customLabelCategory({name, count}) {
      if (name === 'Selecionar categoria') {
        return `${name}`
      } else {
        return `${name} - ${count} posts`
      }
    },
    updateCategoryEditColor(value) {
      this.editCategory.color = value.hex
    },
    updateCategoryCreateColor(value) {
      this.newCategory.color = value.hex
    },
  },
}
</script>

<style lang="scss" scoped>
  .new-category {
    display: flex;
    align-items: center;

    .category-create-name {
      flex: 0 0 40%;
      margin: 0 5px;
    }

    .category-create-icon {
      flex: 0 0 20%;
      margin: 0 5px;
    }

    .pick-color-btn {
      flex: 0 0 20%;
      position: relative;
      margin: 0 5px;

      .new-category-color {
        position: absolute;
        bottom: 0;
        left: 40%;
      }
    }

    .category-create-btn {
      flex: 0 0 20%;
      margin: 0 5px;
    }
  }

  .list-post {
    display: flex;
    align-items: center;

    border: none;
    margin: 10px 0;

    &:hover {
      transition: 300ms;
      cursor: pointer;
    }

    p {margin: 0;}

    .list-post-slug {
      flex: 0 0 40%;
    }
    .list-post-title {
      flex: 0 0 40%;
    }
    .list-post-date {
      flex: 0 0 30%;
    }
  }

  .list-post-draft { background: rgba(200,200,200,1); &:hover {background: rgba(180,180,180,1);}}
  .list-post-retired { background: rgba(200,150,150,1);  &:hover {background: rgba(180,120,120,1);}}

  h2 {
    margin-top: 40px;
  }

  h5 {
    font-size: 18px;
    margin-top: 30px;
    margin-bottom: 5px;
    padding-left: 5px;
  }
</style>

