<template>
  <div>
    <p :style="{ backgroundColor: category.color }" class="post-category"><i :class="category.icon"></i>{{category.name}}</p>
  </div>
</template>

<script>
export default {
  name: "CategoryLabel",
  props: ['category']
}
</script>

<style lang="scss" scoped>
  .post-category {
    padding: 6px;
    border-radius: 2px;
    font-size: 13px;

    color: white;

    i {
      padding-right: 10px;
    }
  }
</style>

