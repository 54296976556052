<template>
  <transition name="fade">
    <div class="modal-mask" v-if="showModal" @click="showModal = !closable"
      @keyup.esc="showModal = !closable">
      <div :class="'modal ' + position" tabindex="-1" role="dialog"
        aria-labelledby="modal-label" v-focus>
        <div class="modal-dialog" role="document" @click.stop>
          <div class="modal-content">
            <div class="modal-header" v-if="position === 'fixed-right' || title">
              <button type="button" class="modal-close" data-dismiss="modal"
                aria-label="Close" @click="showModal = false">
                <icon name="close" />
              </button>
              <h4 class="modal-title" id="modal-label">
                {{ title }}
              </h4>
            </div>
            <div class="modal-body">
              <component :is="child" v-bind="childProps" :id="id" :parent-id="parent_id"
                ref="form" @close="showModal = false" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
  export default {
    name: "modal",
    data() {
      return {
        showModal: false,
        title: "",
        child: "",
        childProps: {},
        position: "fixed-right",
        id: undefined,
        parent_id: 100,
        closable: true,
      };
    },
    watch: {
      showModal(value) {
        document.body.style.overflow = value ? "hidden" : "";
      },
    },
    mounted() {
      document.querySelectorAll("[data-form]").forEach((el) => {
        const form = el.getAttribute("data-form");
        const id = el.getAttribute("data-id");
        const parentId = el.getAttribute("data-parent-id");
        const position = el.getAttribute("data-position");
        el.addEventListener("click", () => this.show(form, id, parentId, position));
      });
      window.$modal = this;
    },
    methods: {
      show(child, id, parent_id, position = "fixed-right", childProps = {},
        _closable = true) {
        this.child = child;
        this.childProps = childProps;
        this.id = id;
        this.parent_id = parent_id;
        this.position = position || this.position;
        this.showModal = true;
        this.closable = _closable;
        this.$nextTick(() =>
          this.title = this.$refs.form.title && this.$refs.form.title()
        );
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import "../../stylesheets/base/variables";

  .modal-mask {
    background-color: $black-500;
    height: 100%;
    left: 0;
    overflow-y: auto;
    position: fixed;
    top: 0;
    transition: all 0.5s ease;
    width: 100%;
    z-index: 9998;
    &.fade-enter,
    &.fade-leave-to {
      .fixed-right {
        transform: translateX(100%);
      }
      .fixed-top {
        transform: translateY(-70%);
      }
    }
    .modal {
      bottom: 0;
      display: block;
      left: 0;
      overflow: hidden;
      position: fixed;
      right: 0;
      top: 0;
      transform: none;
      transition: transform 1s ease;
      z-index: 1050;

      background: $white-primary;
      border-radius: 8px;
      box-shadow: 0 0 0 1px $black-80, 0 12px 24px 0 $black-200;
      margin: 4% auto;
      max-width: 800px;
      overflow: visible;
      height: 60vh;

      .modal-dialog {
        margin: 50px;
        height: 40vh;
        .modal-content {
          height: 100%;
          width: 600px;
          border: none;
          .modal-body {
            padding: 0;
          }
        }
      }

      .modal-header {
        padding: 24px 24px 0;
        .modal-close {
          background: transparent;
          border: 0;
          cursor: pointer;
          float: right;
          font-size: 21px;
          font-weight: bold;
          opacity: 0.54;
          padding: 0;
          text-shadow: 0 1px 0 $white-primary;
          &:hover {
            opacity: 1;
          }
        }
        .modal-title {
          color: $black-500;
          font-size: 20px;
          font-weight: 500;
        }
      }
    }
  }
</style>
