<template>
  <button :class="{
            'btn-primary': primary,
            'btn-secondary': secondary,
            'btn-outline': outline,
            'btn-success': success,
            'btn-danger': danger,
            'btn-flat': flat,
            'btn-raised': !flat && !outline,
          }"
    :type="submit ? 'submit' : 'button'"
    :disabled="disabled"
    class="btn waves-effect centered"
    @click="$emit('click')" ref="buttonElement">
    <fade-transition out-in>
      <progress-spinner v-if="loading" small />
      <span v-else>
        <slot />
      </span>
    </fade-transition>
  </button>
</template>

<script>
  export default {
    name: "btn",
    props: {
      primary: {
        type: Boolean,
        default: false,
      },
      secondary: {
        type: Boolean,
        default: false,
      },
      outline: {
        type: Boolean,
        default: false,
      },
      success: {
        type: Boolean,
        default: false,
      },
      danger: {
        type: Boolean,
        default: false,
      },
      flat: {
        type: Boolean,
        default: false,
      },
      submit: {
        type: Boolean,
        default: false,
      },
      loading: {
        type: Boolean,
        default: false,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        buttonMinWidth: 0,
      };
    },
  };
</script>

<style lang="scss" scoped>
  .centered {
    text-align: -webkit-center;
  }

  .btn.btn-raised {
    min-width: 160px;

    &:hover,
    &[disabled],
    &.selected {
      opacity: 1;
    }

    .progress-spinner {
      margin: 0 auto;
    }
  }
</style>
