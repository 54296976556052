<template>
  <slide class="featured-post" @slide-click="openCarouselPost(post)" >
    <div class="post-image" :style="{ backgroundImage: `url('${post.thumbnail}')` }">
      <div class="post-details">
        <h2 class="post-title">{{post.title}}</h2>
        <p class="post-description">{{post.description}}</p>
        <p class="post-date">{{post.date}}</p>
      </div>
    </div>
  </slide>
</template>

<script>
export default {
  name: "FeaturedPost",
  props: ['post'],
  data: function () {
    return {
    }
  },
}
</script>

<style lang="scss" scoped>

</style>

