<template>
  <div>
    <router-link class="return-button" :to="'/'">
      <p>&laquo; {{ t("components.return") }}</p>
    </router-link>

    <input
      type="text"
      placeholder="Slug"
      v-model="post.slug"
      class="input-title"
    />

    <input
      type="text"
      placeholder="URL da imagem do post"
      v-model="post.thumbnail"
      class="input-url"
    />

    <div
      v-if="!post.published_at"
      class="strip bg-danger"
      style="display: block"
    >
      Este post está em rascunho desde
      {{ post.created_at && timestampToDate(post.created_at) }}
    </div>

    <div v-if="post.retired_at" class="strip bg-danger" style="display: block">
      Este post foi aposentado em
      {{ post.retired_at && timestampToDate(post.retired_at) }}. <br />
      Motivo: {{ post.retire_reason }}
    </div>

    <input
      type="text"
      placeholder="Título do post"
      v-model="post.content.title"
      class="input-title"
    />
    <textarea
      placeholder="Descrição do post"
      v-model="post.content.description"
      class="input-description"
    ></textarea>

    <select v-model="post.kind" class="control">
      <option v-for="kind in kinds" :value="kind.value">
        {{ kind.label }}
      </option>
    </select>

    <div v-if="post.kind === 'article'">
      <div class="strip bg-secondary draft-info">
        <p>
          Último rascunho salvo em
          {{
            post.content.draft_saved_at &&
            timestampToDateAndTime(post.content.draft_saved_at)
          }}
        </p>
        <btn primary raised @click="recoverDraft">Recuperar versão</btn>
      </div>

      <div class="quill-editor">
        <quill-editor
          v-model="post.content.article"
          ref="editor"
          :options="editorOption"
          @blur="onEditorBlur($event)"
          @focus="onEditorFocus($event)"
          :style="quillStyle"
        >
        </quill-editor>
      </div>
    </div>

    <div v-if="post.kind === 'notebook'" class="margins">
      <div class="flex" v-for="(url, index) in post.image_urls" :key="index">
        <input
          type="text"
          placeholder="URL da imagem"
          v-model="post.image_urls[index]"
          class="input-url"
        />

        <div
          class="btn btn-danger"
          style="display: block"
          @click="post.image_urls.splice(index, 1)"
        >
          Remove
        </div>
      </div>

      <div class="btn btn-primary" style="display: block" @click="addImage()">
        Add image
      </div>
    </div>

    <div
      class="btn btn-primary send-btn"
      style="display: block"
      @click="save()"
    >
      Salvar post
    </div>

    <hr />

    <div
      v-if="!post.published_at"
      class="btn btn-danger"
      style="display: block"
      @click="publish()"
    >
      Este post ainda não foi publicado. Clique aqui para publicar
    </div>

    <div v-else class="strip bg-primary" style="display: block">
      Post publicado em {{ timestampToDate(post.published_at) }}
    </div>

    <div
      v-if="post.published_at && !post.retired_at"
      class="btn btn-danger"
      style="display: block"
      @click="retire()"
    >
      Aposentar artigo
    </div>

    <div
      v-if="post.retired_at"
      class="btn btn-danger"
      style="display: block"
      @click="reactivate()"
    >
      Este post está aposentado. Clique aqui para reativá-lo
    </div>

    <div
      v-if="post.published_at"
      class="btn btn-secondary"
      style="display: block"
      @click="viewPost()"
    >
      Visualizar post
    </div>
  </div>
</template>

<script>
import Quill from "quill";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import Multiselect from "vue-multiselect";

export default {
  name: "text-editor",
  data() {
    return {
      requireAdmin: true,
      currentTab: "en",
      post: {
        previous_slug: "",
        slug: "",
        content: {},
        thumbnail: "",
        image_urls: [],
        published_at: null,
        retired_at: null,
        reactivated_at: null,
        draft_saved_at: null,
        draft: null,
        kind: "article",
      },
      draftSaveTimer: null,
      draftSaveSeconds: 120,
      categories: [],
      levels: [],
      kinds: [
        { value: "article", label: "Article" },
        { value: "notebook", label: "Notebook" },
      ],
      editorOption: {
        modules: {
          toolbar: {
            container: [
              [
                "bold",
                "italic",
                "underline",
                "strike",
                "link",
                "image",
                "blockquote",
              ],
              [{ header: [1, 2, 3, 4, 5, 6, false] }],
              [{ list: "ordered" }, { list: "bullet" }],
              [{ indent: "-1" }, { indent: "+1" }],
              [{ size: ["small", false, "large", "huge"] }],
              [{ color: [] }, { background: [] }],
              [{ font: [] }],
              [{ align: [] }],
            ],
            handlers: {
              image: this.imageHandler,
            },
          },
        },
      },
      quillStyle: {
        height: "100vh",
      },
    };
  },
  components: {
    quillEditor,
    Multiselect,
  },
  methods: {
    onEditorBlur(quill) {},
    onEditorFocus(quill) {},
    imageHandler() {
      const editor = this.$refs.editor.quill;
      const range = editor.getSelection();
      const value = prompt("URL da imagem para adicionar");
      editor.insertEmbed(range.index, "image", value, Quill.sources.USER);
    },
    async save() {
      const postSlug = this.post.previous_slug;

      const url = `/client/posts/${postSlug}`;
      const response = await axios.patch(url, this.post);

      this.$emit("show-snackbar", "success", "Artigo atualizado com sucesso");
      this.post = response.data;
    },
    async publish() {
      const postSlug = this.$route.params.slug;

      const url = `/client/posts/publish/${postSlug}`;

      await axios
        .patch(url)
        .then((response) => {
          if (response.data.post) {
            this.post = response.data.post;
            this.$emit(
              "show-snackbar",
              "success",
              "Post publicado com sucesso"
            );
          } else {
            this.$emit("show-snackbar", "failure", `${response.data.errors}`);
          }
        })
        .catch(() => {
          this.$emit("show-snackbar", "failure", "Erro ao publicar post");
        });
    },
    async publish() {
      const postSlug = this.$route.params.slug;

      const url = `/client/posts/publish/${postSlug}`;

      await axios
        .patch(url)
        .then((response) => {
          if (response.data.post) {
            this.post = response.data.post;
            this.$emit(
              "show-snackbar",
              "success",
              "Post publicado com sucesso"
            );
          } else {
            this.$emit("show-snackbar", "failure", `${response.data.errors}`);
          }
        })
        .catch(() => {
          this.$emit("show-snackbar", "failure", "Erro ao publicar post");
        });
    },
    async retire() {
      const postSlug = this.$route.params.slug;

      const retireReason = prompt(
        "Digite um breve motivo para aposentar o artigo"
      );

      const url = `/client/posts/retire/${postSlug}`;

      await axios
        .patch(url, { retire_reason: retireReason })
        .then((response) => {
          if (response.data.post.retired_at) {
            this.post = response.data.post;
            this.$emit(
              "show-snackbar",
              "success",
              "Post aposentado com sucesso"
            );
          } else {
            this.$emit(
              "show-snackbar",
              "failure",
              "Não foi possível aposentar esse post"
            );
          }
        })
        .catch(() => {
          this.$emit(
            "show-snackbar",
            "failure",
            "Não foi possível aposentar esse post"
          );
        });
    },
    async reactivate() {
      const postSlug = this.$route.params.slug;

      const url = `/client/posts/reactivate/${postSlug}`;

      await axios
        .patch(url)
        .then((response) => {
          if (!response.data.post.retired_at) {
            this.post = response.data.post;
            this.$emit(
              "show-snackbar",
              "success",
              "Post reativado com sucesso"
            );
          } else {
            this.$emit(
              "show-snackbar",
              "failure",
              "Não foi possível reativar esse post"
            );
          }
        })
        .catch(() => {
          this.$emit(
            "show-snackbar",
            "failure",
            "Não foi possível reativar esse post"
          );
        });
    },
    addImage() {
      this.post.image_urls.push("");
    },
    viewPost() {
      this.$router.push({ name: "post", params: { slug: this.post.slug } });
    },
    async saveDraft() {
      const draft = this.post.content.article;
      if (draft < 20) return false;

      const postSlug = this.post.previous_slug;
      const url = `/client/posts/save_draft/${postSlug}`;

      await axios
        .patch(url, { draft })
        .then((response) => {
          if (response.data.post) {
            console.log(response.data);
            this.post.content.draft = response.data.content.draft;
            this.post.content.draft_saved_at =
              response.data.content.draft_saved_at;
            this.$emit(
              "show-snackbar",
              "success",
              "Rascunho salvo automaticamente"
            );
          } else {
            this.$emit("show-snackbar", "failure", "Erro ao salvar rascunho");
          }
        })
        .catch(() => {
          this.$emit("show-snackbar", "failure", "Erro ao salvar rascunho");
        });
    },
    recoverDraft() {
      const confirmation = confirm(
        "Você tem certeza? O rascunho irá sobrepor o conteúdo atual."
      );
      if (confirmation) {
        this.post.content.article = this.post.content.draft;
        this.$emit("show-snackbar", "success", "Rascunho recuperado");
      } else {
        this.$emit("show-snackbar", "failure", "Rascunho não recuperado");
      }
    },
    async loadPost() {
      const postSlug = this.$route.params.slug;

      if (postSlug) {
        const url = `/client/posts/${postSlug}/edit`;
        const response = await axios.get(url);
        console.log(response.data);

        this.post = response.data;
      }
    },
  },
  async mounted() {
    const categoriesURL = "/client/categories";
    const response = await axios.get(categoriesURL);
    this.categories = response.data.categories;

    const responseLevels = await axios.get("/client/levels");
    this.levels = responseLevels.data.levels;

    const postSlug = this.$route.params.slug;

    if (!postSlug) {
      const url = `/client/posts/`;
      const response = await axios.post(url);

      this.$emit(
        "show-snackbar",
        "success",
        `Artigo em rascunho criado com id #${response.data.post.slug}`
      );

      this.$router.push({
        name: "text-editor-edit",
        params: { slug: response.data.post.slug },
      });
    }

    this.loadPost();

    this.draftSaveTimer = setInterval(() => {
      this.saveDraft();
    }, 1000 * this.draftSaveSeconds);
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss" scoped>
.quill-editor {
  margin: 50px 0;
}

.margins {
  margin: 20px 0;
}

.tabs {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 40px;
}

.tab {
  flex: 0 0 20%;
  font-size: 18px;
  padding: 12px 8px;
  color: #161616;
  text-align: center;

  &:hover {
    background-color: #646464;
    cursor: pointer;
    color: white;
    transition: 300ms;
  }
}

.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
}

.active {
  background-color: #161616;
  color: white;
}

.draft-info {
  margin-top: 40px;
  display: flex;
  align-items: center;
  padding: 0 20px;

  p {
    flex: 0 0 80%;
    margin: 0;
    color: white;
  }

  button {
    flex: 0 0 20%;
  }
}

.selects {
  margin: 30px 0;
  display: flex;
  align-items: center;

  & > * {
    margin: 0 30px 0 0;
  }
}

.btn {
  margin: 10px 0;
}

.ql-editor {
  h1 {
    font: 32px Arial, Helvetica, sans-serif !important;
    color: rgba(20, 20, 20, 1) !important;
    font-weight: 700 !important;
    margin-bottom: 30px !important;
    text-align: left !important;
  }

  h2 {
    font: 28px Arial, Helvetica, sans-serif !important;
    color: red !important;
    font-weight: 300 !important;
    margin-bottom: 20px !important;
    text-align: left !important;
  }

  h3 {
    font: 25px Arial, Helvetica, sans-serif;
    color: rgba(60, 60, 60, 1);
    font-weight: 300;
    margin-bottom: 18px;
    text-align: left;
  }

  h4 {
    font: 22px Arial, Helvetica, sans-serif;
    color: rgba(60, 60, 60, 1);
    font-weight: 300;
    margin-bottom: 16px;
    text-align: left;
  }

  h5 {
    font: 20px Arial, Helvetica, sans-serif;
    color: rgba(60, 60, 60, 1);
    font-weight: 300;
    margin-bottom: 16px;
    text-align: left;
  }

  p {
    font: 18px Arial, Helvetica, sans-serif;
    color: rgba(80, 80, 80, 1);
    font-weight: 300;
    margin-bottom: 10px;
    text-align: justify;
  }

  br {
  }
}
</style>

<!--     <div class="selects">
      <multiselect v-model="post.category" :options="categories" placeholder="Categoria" label="name" track-by="id" />
      <multiselect v-model="post.level" :options="levels" placeholder="Nível" label="name" track-by="id"/>
    </div>
 -->
