import VueRouter from "vue-router";
import Vue from 'vue'

const lazy = (name) => () => Promise.resolve(Vue.component(name));

function getComponentFromPath(path) {
  if (path.includes('/')) {
    return path.substr(0, path.indexOf('/'))
  } else {
    return path
  }
};

// Load all components in javascript/packs/components

const requireComponents = require.context("./components", true, /.vue$/);
const registeredComponents = []

requireComponents.keys().forEach((fileName) => {
  const component = requireComponents(fileName);
  const componentName = component.default.name;

  registeredComponents.push(componentName)

  if (component.default.el === "application") {
    return;
  }

  Vue.component(componentName, component.default || component);
});

const router = new VueRouter({
  mode: "history",
  routes: [
    // ---------------------------
    // Redirect logic
    // ---------------------------
    {
      path: '/',
      name: 'Redirect',
      redirect: function (to) {
        const path = to.query.redirect
        if (typeof path === 'undefined') {
          return {
            name: 'home'
          }
        } else if (registeredComponents.includes(getComponentFromPath(path))) {
          return {
            path: to.query.redirect,
            query: ''
          }
        } else {
          return {
            path: '/posts',
            query: ''
          }
        }
      }
    },
    // ---------------------------
    // Components
    // ---------------------------
    {
      path: "/",
      component: lazy('home'),
      name: "home",
    },
    {
      path: "/posts",
      component: lazy('home'),
      name: "posts",
    },
    {
      path: "/page-not-found",
      component: lazy('page-not-found'),
      name: "page-not-found",
    },
    {
      path: "/post/:slug",
      component: lazy('post'),
      name: "post",
    },
    {
      path: "/admin-login",
      component: lazy('admin-login'),
      name: "admin-login",
    },
    {
      path: "/text-editor",
      component: lazy('text-editor'),
      name: "text-editor-new",
    },
    {
      path: "/text-editor/:slug",
      component: lazy('text-editor'),
      name: "text-editor-edit",
    },
    {
      path: "/admin-dashboard",
      component: lazy('admin-dashboard'),
      name: "admin-dashboard",
    },
    {
      path: "/about",
      component: lazy('about'),
      name: "about",
    },
  ],
});

export default router;
