<template>
  <div id="admin-login" class="center-content">
    <div class="login-box">
      <h1>Acesso de administrador</h1>
      <input type="password" ref="password" autofocus v-model="password" class="form-control" id="admin-password" placeholder="Digite a palavra-chave" @keyup.enter="authenticate()">
    </div>
  </div>
</template>

<script>
export default {
  name: "admin-login",
  data: function () {
    return {
      password: '',
    }
  },
  methods: {
    async authenticate() {
      const response = await axios.patch(`/client/sessions/${this.$store.getters.sessionId}`, {
        password: this.password,
      })

      if (response.data.admin === true) {
        this.$store.commit('upgradeToAdmin')
        this.$router.push({ name: 'admin-dashboard' })
        this.$emit('show-snackbar', 'success', 'Acesso de administrador garantido')
      } else {
        this.$router.push({ name: 'home' })
        this.$emit('show-snackbar', 'failure', 'Acesso de adminsitrador negado')
      }
    }
  },
  mounted() {
    if (this.$store.getters.sessionAdmin) {
      this.$router.push({ name: 'admin-dashboard' })
    }

    this.$refs.password.focus()
  }
}
</script>

<style lang="scss" scoped>
  #admin-login {
    height: 80vh;
  }

  .login-box {
    background: #102030;
    padding: 100px 100px;
    width: 70vw;

    h1 {
      color: white;
      margin-bottom: 30px;
    }
  }

  @media screen and (max-width: 768px) {
    .login-box {
      padding: 20px 20px;
      width: 80vw;

      h1 {
        font-size: 24px;
      }
    }

  }
</style>
