<template>
  <div>
    <div id="footer">
      <div class="footer">
        <h2 class="lunacali-logo" @click="goToAbout">
          l
        </h2>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  methods: {
    goToAbout() {
      this.$router.push({ name: 'about' })
    },
  }
}
</script>

<style lang="scss" scoped>
  a:link, a:active, a:visited, a:hover {
    text-decoration: none;
  }

  .fa-circle {
    font-size: 26px;
    color: white;
    text-align: left;
    margin-bottom: 0;
    padding: 10px 20px;
    position: relative;
    top: 15px;
  }

  .footer {
    background: #102030;
    height: 80px;
    padding: 0px 50px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;

    .lunacali-logo {
      font-size: 30px;
    }
  }

</style>

