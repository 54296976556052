<template>
  <div id="app">
    <div id="page-not-found" class="full-page center-content">
      <div>
        <h1>Página não encontrada</h1>
        <p>Certifique-se de que entrou na página correta</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "page-not-found",
}
</script>

<style scoped>
  #page-not-found {
    background: red;
  }
</style>
