<template>
  <div>
    <h1 class="page-title">Publicações</h1>

    <div class="controls">
      <p class="control-label">Ordenar por</p>
      <select v-model="sortKey" class="control">
        <option v-for="option in sortKeys" :value="option.value">{{option.name}}</option>
      </select>
      <select v-model="sortDirection" class="control">
        <option v-for="option in sortDirections" :value="option.value">{{option.name}}</option>
      </select>
      <btn primary @click="loadData" class="control">Carregar dados</btn>
    </div>

    <div class="posts" :class="{loading: loading}">
      <div class="post header">
        <div class="post-thumbnail"></div>
        <div class="post-details">
          <p class="main">Título</p>
          <p class="sub">Data de publicação</p>
        </div>
        <div class="post-visits">
          <p class="main">Visitas totais</p>
          <p class="sub">Visitas no último mês</p>
        </div>
        <div class="post-claps">
          <p class="main">Palmas</p>
          <p class="sub">Palmas por visita</p>
        </div>
        <div class="post-avg-visit">
          <p class="main">Duração média da visita</p>
        </div>
      </div>

      <router-link v-for="post in posts" :to="'post/' + post.slug">
        <div class="post clickable">
          <div class="post-thumbnail" :style="{ backgroundImage: `url('${post.thumbnail}')` }">
            <div v-if="post['featured?']" class="post-featured"></div>
          </div>
          <div class="post-details">
            <p class="main">{{post.title}}</p>
            <p class="sub">{{post.published_at | moment("dddd, MMMM Do YYYY") }}</p>
          </div>
          <div class="post-visits">
            <p class="main">{{post.visits_count}}</p>
            <p class="sub">{{post.visits_last_30}}</p>
          </div>
          <div class="post-claps">
            <p class="main">{{post.claps}}</p>
            <p class="sub">{{post.claps_per_visit}}</p>
          </div>
          <div class="post-avg-visit">
            <p class="main">{{post.average_visit_duration}}</p>
            <p class="sub">{{post.word_count}}</p>
          </div>
        </div>
      </router-link>

    </div>
  </div>
</template>

<script>
export default {
  name: "admin-posts",
  data: function () {
    return {
      sortKey: "published_at",
      sortDirection: "-",
      posts: [],
      loading: true,
    }
  },
  async mounted() {
    this.loadData();
  },
  computed: {
    sortKeys() {
      return [
        { name: "Título", value: "title" },
        { name: "Data de publicação", value: "published_at" },
        { name: "Visitas", value: "visits_count" },
        { name: "Visitas no último mês", value: "visits_last_30" },
        { name: "Palmas", value: "claps" },
        { name: "Palmas por visita", value: "claps_per_visit" },
        { name: "Duração média da visita", value: "average_visit_duration" },
        { name: "Contagem de palavras", value: "word_count" },
      ]
    },
    sortDirections() {
      return [
        { name: "Decrescente", value: "-" },
        { name: "Crescente", value: "" },
      ]
    },
    sortBy() {
      return this.sortDirection + this.sortKey;
    },
  },
  methods: {
    async loadData() {
      this.loading = true
      const url = `/client/analytics/post_insights?order_by=${this.sortBy}`
      const response = await axios.get(url)
      this.posts = response.data.posts
      this.loading = false
    }
  },
}
</script>

<style lang="scss" scoped>
  a:link, a:active, a:visited, a:hover {
    text-decoration: none;
  }
  .controls {
    display: flex;
    align-items: center;
    padding: 16px 12px;
    background: rgba(250,250,250,1);
  }
  .control-label {
    flex: 0 0 20%;
    text-align: center;
    margin: 0;
  }
  .control {
    flex: 0 0 25%;
    margin: 0 12px;
  }
  .posts {
    padding: 20px 0;
  }
  .post {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 12px;
    height: 120px;
    background-color: rgba(250,250,250,1);
  }
  .header {
    margin-bottom: 8px;
    height: 120px;
    padding: 12px 0;
    background-color: rgba(30,30,30,1);
    div {
      p {
        color: white;
      }
    }
  }
  .clickable {
    &:hover {
      opacity: 0.9;
      transition: 300ms;
      cursor: pointer;
    }
  }
  .post-thumbnail {
    height: 100%;
    background-size: cover;
    background-position: center;
    flex: 0 0 20%;
    margin-right: 16px;
    position: relative;
  }
  .post-featured {
    position: absolute;
    bottom: 5px;
    right: 5px;
    z-index: 99;
    background-color: gold;
    border-radius: 50%;
    height: 20px;
    width: 20px;
  }
  .post-details {
    flex: 0 0 20%;
  }
  .post-visits {
    text-align: center;
    flex: 0 0 20%;
  }
  .post-claps {
    text-align: center;
    flex: 0 0 20%;
  }
  .post-avg-visit {
    text-align: center;
    flex: 0 0 15%;
  }
  .main {
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 8px;
    color: rgba(30,30,30,1);
  }
  .sub {
    font-size: 16px;
    color: rgba(80,80,80,1);
  }
  .loading {
    opacity: 0.4;
  }
</style>

