<template>
  <div>
    <div class="navbar-spacer" style="height: 120px;" />

    <div class="mobile-padding">
      <h2>{{ t('about.about_title' )}}</h2>

      <p>{{ t('about.about_p1' )}}</p>

      <hr>
      <h2>{{ t('contact.title' )}}</h2>

      <input ref="nameInput" v-model="name" type="text" :placeholder="t('contact.name')" @focus="clearNameError">
      <p class="form-error" v-if="nameError.length">{{nameError}}</p>
      <input ref="emailInput" v-model="email" type="text" :placeholder="t('contact.email')" @focus="clearEmailError">
      <p class="form-error" v-if="emailError.length">{{emailError}}</p>
      <textarea ref="messageInput" v-model="message" :placeholder="t('contact.message')" @focus="clearMessageError" @input="reloadForm"></textarea>
      <p class="form-error" v-if="messageError != false">{{messageError}}</p>

      <btn v-if="!messageSent" primary raised class="full-btn mt-5" @click="validateForm">{{ t('contact.send') }}</btn>
      <btn v-else success raised class="full-btn" @click="messageAlreadySent">{{ t('contact.send_btn_confirm') }}</btn>
    </div>

  </div>
</template>

<script>
export default {
  name: "about",
  data: () => {
    return {
      name: '',
      email: '',
      message: '',
      nameError: '',
      emailError: '',
      messageError: '',
      messageSent: false,
    }
  },
  created() {
    window.scrollTo({top: 0, behavior: 'smooth'})
  },
  computed: {

  },
  methods: {
    validateForm() {
      const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/

      let validation = true

      if (this.name.length < 1) {
        this.nameError = this.t("contact.name_error")
        this.$refs.nameInput.style.borderBottom =  '2px solid red'
        this.$refs.nameInput.style.color =  'red'
        this.$refs.nameInput.classList.add('shaker')
        validation = false
      }

      if (!regex.test(this.email)) {
        this.emailError = this.t("contact.email_error")
        this.$refs.emailInput.style.borderBottom =  '2px solid red'
        this.$refs.emailInput.style.color =  'red'
        this.$refs.emailInput.classList.add('shaker')
        validation = false
      }

      if (this.message.length < 20) {
        this.messageError = this.t("contact.message_error")
        this.$refs.messageInput.style.borderBottom =  '2px solid red'
        this.$refs.messageInput.style.color =  'red'
        this.$refs.messageInput.classList.add('shaker')
        validation = false
      }

      if (validation) {
        this.sendMessage()
      } else {
        this.$emit('show-snackbar', 'failure', this.t("contact.send_error"))
      }
    },
    async sendMessage() {
      const url = '/client/contacts'

      await axios.post(url, { name: this.name, email: this.email, message: this.message, session_id: this.$store.getters.sessionId }).then((response) => {
        if (response.data.result) {
          this.$emit('show-snackbar', 'success', this.ti("contact.send_success", {email: response.data.from}))
          this.$refs.nameInput.style.borderBottom =  '2px solid #26AE40'
          this.$refs.nameInput.style.color =  '#26AE40'
          this.$refs.emailInput.style.borderBottom =  '2px solid #26AE40'
          this.$refs.emailInput.style.color =  '#26AE40'
          this.$refs.messageInput.style.borderBottom =  '2px solid #26AE40'
          this.$refs.messageInput.style.color =  '#26AE40'
          this.messageSent = true
        } else {
          this.$emit('show-snackbar', 'failure', this.t("contact.send_error_simple"))
        }
      }).catch((error) => {
        this.$emit('show-snackbar', 'failure', this.t("contact.send_error_simple"))
      })
    },
    messageAlreadySent() {
      this.$emit('show-snackbar', 'success', this.t("contact.message_already_sent"))
    },
    reloadForm() {
      this.messageSent = false
      this.nameError = ''
      this.$refs.nameInput.style.borderBottom =  '2px solid #102030'
      this.$refs.nameInput.classList.remove('shaker')
      this.$refs.nameInput.style.color =  'black'
      this.emailError = ''
      this.$refs.emailInput.style.borderBottom =  '2px solid #102030'
      this.$refs.emailInput.classList.remove('shaker')
      this.$refs.emailInput.style.color =  'black'
      this.messageError = ''
      this.$refs.messageInput.style.borderBottom =  '2px solid #102030'
      this.$refs.messageInput.classList.remove('shaker')
      this.$refs.messageInput.style.color =  'black'
    },
    clearNameError() {
      this.nameError = ''
      this.$refs.nameInput.style.borderBottom =  '2px solid #102030'
      this.$refs.nameInput.classList.remove('shaker')
      this.$refs.nameInput.style.color =  'black'
    },
    clearEmailError() {
      this.emailError = ''
      this.$refs.emailInput.style.borderBottom =  '2px solid #102030'
      this.$refs.emailInput.classList.remove('shaker')
      this.$refs.emailInput.style.color =  'black'
    },
    clearMessageError() {
      this.messageError = ''
      this.$refs.messageInput.style.borderBottom =  '2px solid #102030'
      this.$refs.messageInput.classList.remove('shaker')
      this.$refs.messageInput.style.color =  'black'
    },
  },
}
</script>

<style lang="scss" scoped>
  hr {
    margin: 50px 0;
  }

  h1 { //Post Main title
    font-family: 'Roboto', sans-serif;;
    font-size: 38px;
    font-weight: 900;
    color: rgba(20,20,20,1);
    margin-bottom: 40px;
    text-align: left;
  }

  h2 { //Content title
    font-family: 'Roboto', sans-serif;;
    font-size: 29px;
    font-weight: 900;
    color: rgba(40,40,40,1);
    margin-bottom: 20px;
    text-align: left;
  }

  h3 { //Content subtitle
    font-family: 'Roboto', sans-serif;;
    font-size: 28px;
    font-weight: 300;
    color: rgba(60,60,60,1);
    font-weight: 300;
    margin-bottom: 18px;
    text-align: left;
  }

  p { //Content body
    font-family: 'Roboto Slab', serif;
    font-size: 19px;
    font-weight: 300;
    color: rgba(70,70,70,1);
    font-weight: 300;
    margin-bottom: 15px;
    line-height: 1.3em;
  }

  input[type="text"] {
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    outline: none;
    display: block;
    width: 100%;
    padding: 7px;
    border: none;
    border-bottom: 2px solid #102030;
    background: transparent;
    margin-bottom: 20px;
    font: 16px Arial, Helvetica, sans-serif;
    height: 45px;
  }

  textarea {
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    outline: none;
    display: block;
    width: 100%;
    padding: 7px;
    border: none;
    border-bottom: 2px solid #102030;
    background: transparent;
    margin-bottom: 30px;
    font: 16px Arial, Helvetica, sans-serif;
    height: 100px;
    resize: none;
  }

  .form-error {
    color: red;
    font-size: 15px;
    font-weight: normal;
  }

  .shaker {
    animation: shake 0.4s cubic-bezier(.36,.07,.19,.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
  }

  @keyframes shake {
    10%, 90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%, 80% {
      transform: translate3d(2px, 0, 0);
    }

    30%, 50%, 70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%, 60% {
      transform: translate3d(4px, 0, 0);
    }
  }
</style>


