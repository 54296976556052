<template>
  <div id="navbar">
    <router-link to="/">
      <h2 class="lunacali-logo">
        lunacali
      </h2>
    </router-link>

    <div class="navbar-links">
      <div class="dropdown navbar-link">
      </div>
      <div class="navbar-link" v-if="sessionAdmin">
        <router-link to="/admin-dashboard">
          <p class="navbar-link-text"><i class="fa fa-star"></i></p>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Navbar",
  computed: {
    sessionAdmin() {
      return this.$store.getters.sessionAdmin
    },
  },
  methods: {
    goToAdmin() {
      this.$router.push({ name: 'admin-dashboard' })
    },
    goToAbout() {
      this.$router.push({ name: 'about' })
    },
    async changeLanguage(lang) {
      const url = `/client/change_language?lang=${lang}`
      await axios.get(url)
      location.reload();
    }
  }
}
</script>

<style lang="scss" scoped>
  a:link, a:active, a:visited, a:hover {
    text-decoration: none;
  }

  #navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #102030;
    height: 80px;
    padding: 0px 80px;
    position: fixed;
    width: 100%;
    z-index: 200;
  }

  .navbar-link {
    float: right;
    margin-right: 50px;
  }

  .navbar-link-text {
    text-decoration: none;
    font-size: 20px;
    color: white;
    margin: 0;

    &:hover {
      opacity: 0.9;
      cursor: pointer;
      text-decoration: none;
    }
  }

  .fa-star {
    color: gold;
    font-size: 24px;
    margin: 6px 0 0 0;

    &:hover {
      opacity: 0.9;
      cursor: pointer;
    }
  }

  .dropdown-btn {
    background: transparent;

    i {
      color: white;
      font-size: 22px;
    }
  }

  .dropdown-item {
    &:hover {
      cursor: pointer;
    }
  }

  @media screen and (max-width: 768px) {
    #navbar {
      height: 80px;
      padding: 0px 20px;
    }

    .navbar-page-title {
      font: 24px Arial, Helvetica, sans-serif;
      padding: 10px 5px;
    }

    .navbar-link {
      margin-right: 20px;
    }

    .navbar-link-text {
      font-size: 15px;
    }

    .fa-star {
      font-size: 20px;
    }
  }

</style>

