<template>
  <div>
    <router-link :to="'post/' + post.slug">
      <div v-if="!mobileVersion()">
        <div class="each-post">
          <div class="post-thumbnail" :style="{ backgroundImage: `url('${post.thumbnail}')` }">
          </div>
          <div class="post-details">
            <p class="post-date">{{post.date}}</p>
            <h2 class="post-title">{{post.title}}</h2>
            <h2 class="post-description">{{post.description}}</h2>
            <Labels :category="post.category" :level="post.level" />
          </div>
        </div>
      </div>
      <div v-if="mobileVersion()">
        <div class="m-each-post" :style="{ backgroundImage: `url('${post.thumbnail}')` }" @mouseenter="hoverDetails = true" @mouseleave="hoverDetails = false">
          <Labels :category="post.category" :level="post.level" />
          <div class="m-post-details">
            <h2 class="m-post-title">{{post.title}}</h2>
            <p class="m-post-date">{{post.date}}</p>
            <transition name="slide">
              <p class="m-post-description" v-show="hoverDetails">
                {{post.description}}
              </p>
            </transition>
          </div>
        </div>
      </div>
    </router-link>
  </div>

</template>

<script>
export default {
  name: "EachListedPost",
  props: ['post'],
  data: function () {
    return {
      hoverDetails: false,
    }
  },
}
</script>

<style lang="scss" scoped>
  @import "../../stylesheets/base/variables";

  a:link, a:active, a:visited, a:hover {
    text-decoration: none;
  }

  .each-post {
    margin: 10px 0px;

    position: relative;

    height: 225px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    border-radius: 5px;

    background: rgba(248,248,248,1);

    &:hover {
      transition: 300ms ease;
      background: rgba(240,240,240,1);
    }
  }

  .post-thumbnail {
    flex: 0 0 40%;
    height: 100%;

    background-size: cover;
    background-position: center;
  }

  .post-details {
    flex: 0 0 60%;
    padding: 0 50px;
  }

  .post-date {
    font-size: 16px;
    color: black;
  }

  .post-title {
    font-size: 20px;
    font-weight: bold;
    color: black;
  }

  .post-description {
    font-size: 16px;
    color: $black-500;
    text-align: justify;
  }

  .post-category {
    position: absolute;
    top: 20px;
    right: 20px;
    padding: 8px;
    border-radius: 2px;
    font-size: 15px;

    color: white;

    i {
      padding-right: 10px;
    }
  }

  .slide-enter-active {
     -moz-transition-duration: 0.1s;
     -webkit-transition-duration: 0.1s;
     -o-transition-duration: 0.1s;
     transition-duration: 0.1s;
     -moz-transition-timing-function: ease-in;
     -webkit-transition-timing-function: ease-in;
     -o-transition-timing-function: ease-in;
     transition-timing-function: ease-in;
  }

  .slide-leave-active {
     -moz-transition-duration: 0.1s;
     -webkit-transition-duration: 0.1s;
     -o-transition-duration: 0.1s;
     transition-duration: 0.1s;
     -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
     -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
     -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
     transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  }

  .slide-enter-to, .slide-leave {
     max-height: 100px;
     overflow: hidden;
  }

  .slide-enter, .slide-leave-to {
     overflow: hidden;
     max-height: 0;
  }

  // Mobile version

  .m-each-post {
    background-size: cover;
    background-position: center;
    height: 225px;
    margin: 10px 5px;

    position: relative;
  }

  .m-post-details {
    position: absolute;

    bottom: 0;
    right: 0;
    left: 0;
    padding: 0 10px;

    background: rgba(0,0,0,0.4);
    padding: 20px 20px 0 20px;
  }

  .m-post-date {
    font-size: 14px;
    color: white;
  }

  .m-post-title {
    font-size: 16px;
    font-weight: bold;
    color: white;
  }

  .m-post-description {
    font-size: 12px;
    font-weight: normal;
    color: white;
    text-align: justify;
  }
</style>

