<template>
  <div>
    <h4 class="modal-title">
      Aposentar o post "{{$attrs.postTitle}}"
    </h4>

    <p class="modal-description">
      Por favor, apresenta uma descrição breve do motivo para aposentar esse artigo.
    </p>

    <textarea v-model="retireReason" type="text" placeholder="Escreva um motivo"></textarea>

    <btn raised primary class="float-right save-btn" @click="submitRetire">
      Aposentar artigo
    </btn>
  </div>
</template>

<script>
export default {
  name: "retire-post",
  props: "postTitle",
  data () {
    return {
      retireReason: '',
    }
  },
  mounted() {
    console.log(this.$attrs.postTitle)
  },
  methods: {
    async submitRetire() {
      const postSlug = this.$route.params.slug;

      const url = `/client/posts/retire/${postSlug}`;

      await axios.patch(url).then((response) => {
        if (response.data.post.retired_at) {
          this.post = response.data.post
          this.$emit('show-snackbar', 'success', 'Post aposentado com sucesso')
        } else {
          this.$emit('show-snackbar', 'failure', 'Não foi possível aposentar esse post')
        }
      }).catch(() => {
        this.$emit('show-snackbar', 'failure', 'Não foi possível aposentar esse post')
      })

      this.$emit('close')
    },
  },
}
</script>

<style lang="scss" scoped>
  @import "../../stylesheets/base/variables";

  .modal-title {
    color: $blue-700;
    font-size: 25px;
    margin-bottom: 20px;
  }

  .modal-description {
    color: $black-500;
    font-size: 18px;
    margin-bottom: 20px;
  }

  textarea {
    border: 1px solid $grey-200;
    height: 300px;
    resize: none;
  }

  .save-btn {
    margin-top: 10px;
  }

</style>


