<template>
  <div>
    <div id="home-page" class="full-page">
      <div class="posts">
        <div class="each-post" v-for="post, index in posts">
          <FullScreenPost :post="post" :index="index" :posts="posts" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "home",
  data: function () {
    return {
      loading: true,
      posts: [],
      featured: [],
      latest: null,
      categories: [],
      levels: [],
      filters: {
        title: "",
        category: null,
        level: null,
      },
    };
  },
  async mounted() {
    const response = await axios.get("/client/posts");

    window.scrollTo(0, 0);

    this.loading = false;
    this.$emit("load-finish");
    this.posts = response.data.posts;
    this.featured = response.data.featured;
    this.latest = response.data.latest;
  },
  computed: {
    categoriesWithPosts() {
      return this.categories.filter((cat) => cat.count !== 0);
    },
  },
  methods: {
    async filter() {
      const vm = this;

      axios
        .get("/client/posts", {
          params: {
            filters: this.filters,
          },
        })
        .then(function (response) {
          vm.posts = response.data.posts;
        });
    },
    openCarouselPost(post) {
      this.$router.push({ path: `post/${post.slug}` });
    },
  },
};
</script>

<style lang="scss" scoped>
a:link,
a:active,
a:visited,
a:hover {
  text-decoration: none;
}

.home-section {
  font: 26px Arial, Helvetica, sans-serif;
  color: rgba(80, 80, 80, 1);
  font-weight: 700;
  margin: 0px 0 15px 0;
  padding-top: 10px;
  text-align: left;
}

.filters {
  background: #102030;
  padding: 10px 20px;
  border-radius: 10px;
  box-shadow: 1px 1px rgba(200, 200, 200, 1);
  margin-bottom: 15px;

  .row {
    padding: 0 15px;
  }

  .filter-separator {
    padding: 0 10px;
  }

  .post-search-input,
  select {
    border-bottom: 1px solid white;
    color: white;
    width: 100%;
  }

  .post-search-input::placeholder {
    color: rgba(255, 255, 255, 0.5);
  }
}



.post-search-select-option {
  color: black;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active em versões anteriores a 2.1.8 */ {
  opacity: 0;
}

.posts {
  padding-top: 60px;
}

@media screen and (max-width: 768px) {

  .home-section {
    font: 26px Arial, Helvetica, sans-serif;
    padding: 30px 0 15px 0;
    margin: 0 0 5px 0;
  }
}
</style>
