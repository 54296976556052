<template>
  <div>
    <Navbar v-show="loaded || noLoadRequired" />

    <div v-if="!loaded && !noLoadRequired" class="load-waiter" />

    <div v-show="loaded || noLoadRequired" ref="app" id="app">
      <transition :name="routerTransition">
        <router-view
          @show-snackbar="showSnackbar"
          @load-finish="loaded = true"
          class="router-view"
        />
        <span
          class="app-background"
          style="background-color: black; height: 100vh; width: 100vw;"
        ></span>
      </transition>

      <div ref="snackbar" id="snackbar"></div>
    </div>

    <Footer />
  </div>
</template>

<script>
export default {
  el: "application",
  data: function() {
    return {
      message: "Hello Vue!",
      session: null,
      firstLoad: true,
      loaded: false,
      blogImageUrl:
        "https://bitcoinist.com/wp-content/uploads/2019/03/shutterstock_560802985.jpg",
    };
  },
  mounted() {
    this.startSession();
    
    window.addEventListener("beforeunload", (event) => {
      this.finishSession();
    });
    
    this.listenToAdmin();
  },
  computed: {
    showBanner() {
      return this.$route.path === "/home";
    },
    noLoadRequired() {
      return this.$route.path !== "/";
    },
    routerTransition() {
      if (this.$route.path === "/") {
        return "";
      } else {
        return "router-fade";
      }
    },
  },
  methods: {
    listenToAdmin() {
      document.addEventListener("keydown", (event) => {
        if (event.keyCode === 13 && event.ctrlKey && event.shiftKey) {
          window.location = "/admin-login";
        }
      });
    },
    async startSession() {
      const url = `/client/sessions`;

      const response = await axios.post(url, {
        readerKey: localStorage.readerKey,
      });

      if (response.data.admin) this.$store.commit("upgradeToAdmin");

      this.$store.commit("setSessionId", response.data.id);
      console.log("session commited");
      localStorage.readerKey = response.data.readerKey;
    },
    finishSession() {
      axios.delete(`/client/sessions/${this.$store.getters.sessionId}`);
    },
    showSnackbar(status, message) {
      snackbar.innerHTML = message;
      snackbar.classList.remove("success", "failure");
      snackbar.classList.add(status, "show");

      setTimeout(function() {
        snackbar.classList = "";
      }, 3000);
    },
  },
  watch: {
    $route: function(to, from) {},
  },
};
</script>

<style lang="scss">
a:link,
a:active,
a:visited,
a:hover {
  text-decoration: none;
}

.admin-padding {
  padding: 160px;
}

.load-waiter {
  background-color: black;
  height: 100vh;
}

.home-image-place {
  position: relative;
  width: 100%;
  height: 100vh;
  background-color: #161616;
}

.home-image {
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: center;
}

#app {
  position: relative;
  min-height: 100vh;

  background: white;
}

.router-view {
  background: white;
}

@media screen and (max-width: 1400px) {
  .router-view {
  }
}

@media screen and (max-width: 768px) {
  #app {
    padding: 0 0 10px;
  }

  .router-view {
    padding: 0;
  }
}
</style>
