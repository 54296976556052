import "bootstrap";

import Vue from 'vue'
import App from '../app.vue'
import VueRouter from "vue-router";
import router from "./router.js";
import Vuex from 'vuex'
import VueCarousel from 'vue-carousel';
import merge from "deepmerge";

Vue.use(Vuex);
Vue.use(VueRouter);
Vue.use(VueCarousel);
Vue.use(require('vue-moment'));

const requireLocales = require.context("../../../config/locales", true, /.yml$/);
const rawLocales = requireLocales.keys().map((fileName) => requireLocales(fileName));
const currentLocale = document.querySelector("html").getAttribute("lang");
const locales = merge.all(rawLocales);
console.log(locales)
const getLocale = (key, locale) =>
  key.split(".").reduce((o, i) => o && o[i], locales[locale]);
Vue.prototype.t = (key) =>
  getLocale(key, currentLocale) || getLocale(key, "pt") || key;
Vue.prototype.tc = (key, value) => {
  const attr = (value === 1) ? "one" : "other";
  return Vue.prototype.t(`${ key }.${ attr}`).replace("%{count}", value);
};
Vue.prototype.ti = (key, attrs) => {
  const locale = Vue.prototype.t(key);

  return Object.keys(attrs).reduce((current, item) =>
    current.replace(new RegExp(`%{${ item }}`, "g"), attrs[item]), locale);
};
Vue.prototype.$sleep = (time) => new Promise((r) => setTimeout(r, time));

// Create store

export const store = new Vuex.Store({
  state: {
    sessionId: null,
    sessionAdmin: false,
  },
  mutations: {
    setSessionId(state, sessionId) {
      state.sessionId = sessionId;
    },
    upgradeToAdmin(state) {
      state.sessionAdmin = true
    },
  },
  getters: {
    sessionId: state => state.sessionId,
    sessionAdmin: state => state.sessionAdmin,
  },
})

Vue.mixin({
  created: function () {
    if (this.requireAdmin && !store.getters.sessionAdmin) {
      router.push({ name: 'home', params: { failure: 'Access denied'} })
    }
  },
  methods: {
    responseSuccess(response) {
      return response.data.status.toString()[0] === '2'
    },
    timestampToDate(timestamp) {
      const year = timestamp.slice(0,4)
      const month = timestamp.slice(5,7)
      const day = timestamp.slice(8,10)

      return `${day}/${month}/${year}`
    },
    timestampToDateAndTime(timestamp) {
      const hr = timestamp.slice(11,13)
      const min = timestamp.slice(14,16)
      const sec = timestamp.slice(17,19)

      return `${this.timestampToDate(timestamp)} - ${hr}:${min}:${sec}`
    },
    mobileVersion() {
      return screen.width < 768
    },
  },
})

// Load application

import Application from "../app.vue";
if (document.querySelector("application"))
  new Vue(Object.assign({}, Application, { router, store }));
