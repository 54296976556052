<template>
  <div>
    <h1 class="page-title">Sessões e visitas</h1>

    <div class="cards">
      <div class="card" v-for="card in cards">
        <p class="card-value">{{ card.value }}</p>
        <p class="card-label">{{ card.label }}</p>
        <p class="sub-label">{{ card.sub_label }}</p>
        <p class="sub-value">{{ card.sub_value }}</p>
      </div>
    </div>

    <div class="controls">
      <input type="text" v-model="periods" class="control" />
      <select v-model="timePeriod" class="control">
        <option v-for="option in timePeriods" :value="option.value">
          {{ option.text }}
        </option>
      </select>
      <select v-model="entity" class="control">
        <option v-for="option in entities" :value="option.value">
          {{ option.text }}
        </option>
      </select>
      <btn primary @click="loadData" class="control">Carregar dados</btn>
    </div>

    <line-chart
      style="height: 50vh"
      v-if="loaded"
      :chart-data="chartData"
      :options="options"
    ></line-chart>
    <div style="height: 50vh" v-else></div>
  </div>
</template>

<script>
import { Line, mixins } from "vue-chartjs";
const { reactiveProp } = mixins;

export default {
  extends: Line,
  mixins: [reactiveProp],
  name: "admin-analytics",
  data: function () {
    return {
      loaded: false,
      x: [],
      y: [],
      periods: 30,
      timePeriod: "day",
      entity: "session",
      key: "created_at",
      label: null,
      cards: [],
    };
  },
  async mounted() {
    this.loadCards();
    this.loadData();
  },
  computed: {
    entities() {
      return [
        {
          value: "session",
          text: "Sessões",
          label: "Count of sessions",
          key: "created_at",
        },
        {
          value: "visit",
          text: "Visitas",
          label: "Count of visits",
          key: "created_at",
        },
        {
          value: "reader",
          text: "Leitores",
          label: "Count of new readers",
          key: "created_at",
        },
        {
          value: "contact",
          text: "Contatos",
          label: "Count of contacts",
          key: "created_at",
        },
        {
          value: "post",
          text: "Posts",
          label: "Count of new posts",
          key: "published_at",
        },
        {
          value: "history",
          text: "Histories",
          label: "Count of post alterations",
          key: "created_at",
        },
      ];
    },
    timePeriods() {
      return [
        { value: "day", text: "Por dia" },
        { value: "week", text: "Por semana" },
        { value: "month", text: "Por mês" },
      ];
    },
    chartData() {
      return {
        labels: this.x,
        datasets: [
          {
            label: this.label,
            borderColor: "#161616",
            backgroundColor: "#96b5eb",
            pointBackgroundColor: "#161616",
            pointRadius: "2",
            data: this.y,
          },
        ],
      };
    },
    options() {
      return { responsive: true, maintainAspectRatio: false };
    },
  },
  components: {},
  methods: {
    async loadCards() {
      const url = `/client/analytics/summary_cards`;
      const response = await axios.get(url);
      this.cards = response.data.cards;
    },
    async loadData() {
      this.loaded = false;
      this.label = this.entities.find(
        (entity) => entity.value == this.entity
      ).label;
      this.key = this.entities.find(
        (entity) => entity.value == this.entity
      ).key;
      const url = `/client/analytics/sessions_chart?time_period=${this.timePeriod}&periods=${this.periods}&entity=${this.entity}&key=${this.key}`;
      const response = await axios.get(url);
      this.x = response.data.x;
      this.y = response.data.y;
      this.loaded = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.controls {
  display: flex;
  align-items: center;
  padding: 16px 12px;
  background: rgba(250, 250, 250, 1);
}
.control {
  flex: 0 0 25%;
  margin: 0 12px;
}
.cards {
  display: flex;
  align-itens: center;
  justify-content: space-between;
  margin-bottom: 30px;
}
.card {
  width: 20%;
  margin: 0 5px;
  padding: 20px;
  border: 1px solid rgba(100, 100, 100, 1);
  box-shadow: 1px 1px rgba(200, 200, 200, 1);
  border-radius: 8px;
}
.card-value {
  text-align: right;
  font-size: 60px;
  font-weight: bold;
  color: rgba(40, 40, 40, 1);
  margin: 0;
}
.card-label {
  text-align: right;
  font-size: 20px;
  color: rgba(80, 80, 80, 1);
  margin: 0 0 10px 0;
}
.sub-label {
  font-size: 16px;
  font-weight: bold;
  color: rgba(60, 60, 60, 1);
  text-align: right;
  margin: 0;
}
.sub-value {
  font-size: 16px;
  color: rgba(120, 120, 120, 1);
  text-align: right;
}
</style>
